/**
 * @returns An object that next-international can use for starting up
 */
export const generateImportsForI18n = () => {
  const locales = {
    /**
     * @returns the english translations from the en.ts file
     */
    en: () => import('@/locales/en'),
  };

  const selectedLocales = process.env.NEXT_PUBLIC_LOCALES?.split(
    ',',
  ) as string[];

  for (const locale of Object.keys(locales)) {
    if (!selectedLocales.includes(locale)) {
      // NOTE - if we add another locale, this line can be deleted
      // @ts-expect-error Since there is only one locale available typescript throws an error about deleting a key that shouldn't be deleted
      delete locales[locale as keyof typeof locales];
    }
  }

  return locales;
};
