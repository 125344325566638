'use client';
import { useState, useEffect } from 'react';
import { SearchIcon } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import Link from 'next/link';
import { Building01Icon } from '@untitledui-icons/react/line';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { TClinics } from '@/types/app/clinics/type';
import { useScopedI18n } from '@/locales/client';

type TProps = {
  clinicList: TClinics;
};
/**
 * Renders a list of clinics with a search bar to filter clinics by name.
 * @param props - Component properties.
 * @param props.clinicList - The list of clinics to display.
 * @returns The rendered clinic list component.
 */
const ClinicsListComponents = ({ clinicList }: TProps) => {
  const $t = useScopedI18n('homePage');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredClinics, setFilteredClinics] = useState(clinicList.data);

  // Filter clinics based on search query
  useEffect(() => {
    setFilteredClinics(
      clinicList.data.filter((clinic) =>
        clinic.name.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    );
  }, [searchQuery, clinicList.data]);

  return (
    <>
      <div className="grid w-full gap-5 pb-5 md:grid-cols-2">
        <div>
          <h3 className="text-gray-900 typo-d-xs-medium">
            {$t('clinicListing')}
          </h3>
        </div>
        <div className="flex min-w-10 flex-row items-center justify-center rounded-md border border-gray-300">
          <SearchIcon className="text-color-500 size-7 ps-2" />
          <Input
            placeholder={$t('search')}
            className="placeholder:text-color-500 max-w-sm rounded-md border-0 px-2 placeholder:typo-t-md-regular"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      {filteredClinics.length ? (
        <div
          className={cn(
            'flex flex-col items-start justify-start gap-4',
            filteredClinics.length <= 3 && 'h[72vh] md:h-[42vh]',
            filteredClinics.length > 4
              ? 'h[72vh] overflow-y-scroll md:h-[42vh]'
              : 'overflow-y-hidden',
          )}
        >
          {filteredClinics.map((clinic) => (
            <Link
              key={clinic.id}
              className={cn(
                'grid h-fit w-full grid-cols-11 items-center gap-2 rounded-xl border border-gray-200 p-5',
              )}
              href={`/clinics/${clinic.id}/booking`}
            >
              <div className="col-span-2 md:col-span-1">
                <Building01Icon className="size-7 text-gray-800" />
              </div>
              <div className="col-span-7 md:col-span-9">
                <p className="text-gray-900 typo-t-md-regular">
                  {' '}
                  {clinic.name}
                </p>
                <p className="text-gray-600 typo-t-sm-regular">
                  {$t('clinicId')} {clinic.id}
                </p>
              </div>
              <div className="col-span-2 flex justify-end md:col-span-1">
                <ChevronRightIcon className="size-7 text-gray-500" />
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className="h[72vh] pt-5 text-center text-gray-900 typo-t-md-regular md:h-[42vh]">
          <span>{$t('clinicNotFound')}</span>
        </div>
      )}
    </>
  );
};

export default ClinicsListComponents;
